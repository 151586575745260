<template>
  <div>
    <div class="row m-0">
      <div class="col-6">
        <button class="btn back-btn" @click="backToManage"></button>
      </div>
      <div class="col-6" style="display: flex; justify-content: right">
        <label class="request-apply">{{ confirmStatus }}</label>
      </div>
    </div>
    <div class="row m-0" style="margin-left: 24px !important">
      <div class="col-1">
        <img :src="userAvatar" class="demo-avt" />
      </div>
      <div class="col-11">
        <div class="row total-table w-100 mx-0 align-items-start">
          <div class="col-2 emp-number">社員番号：{{ empNumber }}</div>
          <div class="col-2">名前：{{ empName }}</div>
          <div class="col-1">
            <select class="months-list" @change="onChange($event)" v-model="selectedYearMonth">
              <option v-for="(option, idx) in monthsList" :key="idx" :value="option.value">
                {{ option.year }}年{{ Number(option.month) }}月
              </option>
            </select>
          </div>
          <div class="col-7" style="display: flex; justify-content: right; padding-right: 140px">
            <button
              class="reject-btn"
              @click="changeMonthlyStatus('M40')"
              :disabled="confirmStatus !== '確認申請中'"
            >
              <b-icon-shift-fill class="shift-icon" />
              差し戻し
            </button>
            <button
              class="confirm-apply-btn"
              @click="changeMonthlyStatus('M30')"
              :disabled="confirmStatus !== '確認申請中'"
            >
              <b-icon-check class="check-icon"></b-icon-check>
              確認完了
            </button>
          </div>
        </div>
        <div class="row total-table w-100 mx-0 align-items-end">
          <div class="col-11 p-0">
            <total-table />
          </div>
          <div
            class="col-1"
            style="display: flex; justify-content: flex-end; font-size: 25px; color: #000000"
          >
            <b-icon-printer class="pr-1" type="button" />
            <b-icon-download class="pl-1" type="button" />
          </div>
        </div>
      </div>
    </div>
    <div class="row m-0" style="margin: 0">
      <div class="detail-table">
        <b-table :items="items" :fields="fields">
          <template v-slot:head(checkbox)>
            <input
              type="checkbox"
              class="chk-box"
              :checked="isAllChecked"
              @change="checkAllCheckbox"
            />
            <button @click="openCheckboxOption" class="chk-box-option">
              <b-icon-chevron-down></b-icon-chevron-down>
            </button>
          </template>
          <template v-slot:cell(checkbox)="{ item }">
            <input
              type="checkbox"
              class="chk-box"
              :id="'checkbox' + item.id"
              v-model="item.isChecked"
            />
          </template>
          <template v-slot:cell(edit)="{ item }">
            <button class="btn edit-btn" @click="openInformationTab(item, 0)"></button>
          </template>
          <template v-slot:cell(behavior)="{ item }">
            <button class="btn behavior-history-btn" @click="openInformationTab(item, 1)"></button>
          </template>
          <template v-slot:cell(daily)="{ item }">
            <button class="btn daily-report-btn" @click="openInformationTab(item, 2)"></button>
          </template>
        </b-table>
        <checkbox-option
          v-if="items.some((x) => x.isChecked) && chkBoxOption"
          @closeTab="onCloseCheckboxOption"
        ></checkbox-option>
      </div>
      <info-tabs
        v-if="openInfoTab"
        ref="infoTab"
        @onClose="onCloseTab"
        @onUpdate="onUpdateData"
        :allData="allData"
        :selectedTab="selectedTab"
      ></info-tabs>
    </div>
  </div>
</template>

<script>
import avatar from "./../assets/user-default.png";
import totalTable from "./AttendanceManage/TotalTable.vue";
import checkboxOption from "./AttendanceManage/CheckboxOption.vue";
import infoTabs from "./AttendanceManage/InfoTab.vue";
import router from "../router";
import axios from "axios";
import Cookies from "js-cookie";

export default {
  components: { totalTable, checkboxOption, infoTabs },

  data() {
    return {
      avatar,
      userId: "",
      monthlyId: "",
      empNumber: "",
      empName: "",
      confirmStatus: "",
      token: Cookies.get("token").split("|"),
      monthsList: [],
      selectedYearMonth: null,
      userAvatar: avatar,
      fields: [
        { key: "checkbox", label: "" },
        { key: "edit", label: "編集" },
        { key: "behavior", label: "行動履歴" },
        { key: "daily", label: "日報" },
        { key: "date", label: "日付" },
        { key: "dayOfWeek", label: "曜日" },
        { key: "dateType", label: "予定" },
        { key: "startWorking", label: "出勤時刻" },
        { key: "endWorking", label: "退勤時刻" },
        { key: "breakTime", label: "休憩時間" },
        { key: "actualWorkingTime", label: "実働時間" },
        { key: "outsideWorkingTime", label: "所定外" },
        { key: "scheduledWorkingHour", label: "所定内" },
        { key: "overtime", label: "時間外" },
        { key: "midnightTime", label: "深夜" },
      ],

      items: [],
      chkBoxOption: false,
      openInfoTab: false,
      isChecked: false,
      isAllChecked: false,
      allData: null,
      selectedTab: 0,
    };
  },

  mounted() {
    //get the user_id from router
    this.userId = this.$route.query.id;
    this.getYearMonthList();
  },

  methods: {
    // close checkbox option
    onCloseCheckboxOption() {
      this.chkBoxOption = false;
      this.getMonthlyDetailData();
    },

    // get list year-month display on dropdown
    async getYearMonthList() {
      axios.defaults.headers.common = {
        Authorization: "Bearer " + this.token[1],
        "X-Api-Key": this.$store.state.headers["x-api-key"],
      };

      //get data from api
      const { data } = await axios.get(this.$store.state.serverUrl + "attendance/year-month/v1", {
        params: {
          user_id: this.userId,
        },
      });

      data.result?.forEach((el) => {
        let year = String(el.year_month).substr(0, 4);
        let month = String(el.year_month).substr(4, 2);

        let value = {
          key: el.monthly_info_id,
          year: year,
          month: month,
          value: el.year_month,
        };
        this.monthsList.push(value);
      });
      this.selectedYearMonth = this.monthsList[this.monthsList?.length - 1].value;
      // call api get monthly data
      this.getMonthlyDetailData();
    },

    //when select year-month at dropdown
    onChange(e) {
      this.selectedYearMonth = e.target.value;
      this.getMonthlyDetailData();
    },

    //get data API SW-0202
    getMonthlyDetailData() {
      this.items = [];
      let that = this;
      axios.defaults.headers.common = {
        Authorization: "Bearer " + that.token[1],
        "X-Api-Key": that.$store.state.headers["x-api-key"],
      };
      // get data from API
      axios
        .get(that.$store.state.serverUrl + "attendance/monthly/v1", {
          params: {
            user_id: that.userId,
            month: that.selectedYearMonth,
            tenant_id: Cookies.get("tenantId"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            const monthlyData = response.data.result;
            that.monthlyId = monthlyData.monthly_attendance_id;
            that.empName = monthlyData.first_name + " " + monthlyData.last_name;
            that.empNumber = monthlyData.employee_number;
            that.confirmStatus = that.getConfirmStatus(
              monthlyData.monthly_attendance_confirm_status
            );
            if (monthlyData.avatar_img_path) {
              that.userAvatar = "data:image/(png|jpg|jpeg);base64," + monthlyData.avatar_img_path;
            }
            that.$store.dispatch("attendance/getDataMonthly", monthlyData);

            // format data
            const attendances = monthlyData.attendances;
            attendances.forEach((element) => {
              const subArray = {
                id: element.id,
                isChecked: false,
                fullDate: element.date,
                dateTypeCode: element.date_type_code,
                napTime: element.nap_time,
                awaitTime: element.await_time,
                date: that.formatDate(element.date),
                dayOfWeek: that.getDayOfWeek(element.date),
                dateType: element.work_schedule_name,
                startWorking: element.work_started_at,
                endWorking: element.work_ended_at,
                breakTime: element.break_time === null ? null : element.break_time,
                actualWorkingTime:
                  element.actual_working_time === null ? null : element.actual_working_time,
                outsideWorkingTime:
                  element.outside_of_working_time === null ? null : element.outside_of_working_time,
                scheduledWorkingHour:
                  element.scheduled_working_hours === null ? null : element.scheduled_working_hours,
                overtime: element.overtimes === null ? null : element.overtimes,
                midnightTime: element.midnight_time === null ? null : element.midnight_time,
              };
              // push data to items array
              that.items.push(subArray);
            });
          }
        })
        .catch(() => {});
    },

    //get monthly confirm status
    getConfirmStatus(statusCode) {
      switch (statusCode) {
        case "M40":
          return "差し戻し";
        case "M30":
          return "確認完了";
        case "M20":
          return "引き戻し";
        case "M10":
          return "確認申請中";
        default:
          return "未処理";
      }
    },

    //format date data
    formatDate(value) {
      if (value !== null) {
        const date = new Date(value);
        const day = date.getDate();
        const month = date.getMonth() + 1;

        return month + "/" + day;
      } else {
        return null;
      }
    },

    //get day of week
    getDayOfWeek(value) {
      if (value !== null) {
        const date = new Date(value);
        let day = date.getDay();
        switch (day) {
          case 1:
            return "月";
          case 2:
            return "火";
          case 3:
            return "水";
          case 4:
            return "木";
          case 5:
            return "金";
          case 6:
            return "土";
          default:
            return "日";
        }
      }
    },

    //check all checkbox in table
    checkAllCheckbox() {
      const status = !this.isAllChecked;
      this.isAllChecked = status;
      const newItems = this.items.map((x) => {
        return {
          ...x,
          isChecked: status,
        };
      });
      this.items = [...newItems];
    },

    //back to attendance manage screen
    backToManage() {
      return router.push({ name: "AttendanceManage" });
    },

    //close info tab
    onCloseTab() {
      this.openInfoTab = false;

      //set table width when not displaying tab
      document.getElementsByClassName("detail-table")[0].style.width = "-webkit-fill-available";
    },

    async getOperationData(date) {
      //get daily data SW-0204
      axios.defaults.headers.common = {
        Authorization: "Bearer " + this.token[1],
        "X-Api-Key": this.$store.state.headers["x-api-key"],
      };
      const { data } = await axios.get(this.$store.state.serverUrl + "attendance/daily/v1", {
        params: {
          user_id: this.userId,
          date: date,
          tenant_id: Cookies.get("tenantId"),
        },
      });
      this.$store.dispatch("attendance/getAllData", data.result);
      this.allData = data.result;
    },

    //open information tab (edit/behavior history/daily report)
    async openInformationTab(value, selectTab) {
      this.openInfoTab = true;
      this.selectedTab = selectTab; //0: edit, 1: behavior history, 2: daily report

      if (value !== null) {
        let detail_data = {
          id: value.id,
          napTime: Number(value.napTime),
          awaitTime: Number(value.awaitTime),
          startWorking: value.startWorking,
          endWorking: value.endWorking,
          breakTime: Number(value.breakTime),
          actualWorkingTime: Number(value.actualWorkingTime),
          outsideWorkingTime: Number(value.outsideWorkingTime),
          scheduledWorkingHour: Number(value.scheduledWorkingHour),
          overtime: Number(value.overtime),
          midnightTime: Number(value.midnightTime),
        };

        const payload = {
          date: value.date,
          full_date: value.fullDate,
          day_of_week: value.dayOfWeek,
          detail_data: detail_data,
          edit_tab: true,
          behavior_tab: false,
          daily_report: false,
        };
        //get data in row to show in tab
        this.$store.dispatch("attendance/showInfoTab", payload);
      }
      //set table width when displaying tab
      document.getElementsByClassName("detail-table")[0].style.width = "calc(100% - 574px)";

      // get data display on info tab
      if (this.selectedTab === 0) {
        this.$nextTick(() => this.$refs.infoTab.attendanceDataFormat(value.fullDate));
      } else if (this.selectedTab === 1) {
        this.$nextTick(() => this.$refs.infoTab.getDriverRegulationData());
      } else {
        this.$nextTick(() => this.$refs.infoTab.getDailyReportData());
      }
    },

    //when update data at tab
    onUpdateData(value) {
      const oldItems = [...this.items];
      let index = this.items.findIndex((x) => {
        return x.id === value.id;
      });

      oldItems[index].actualWorkingTime = value.actualWorkingTime;
      oldItems[index].breakTime = value.breakTime;
      oldItems[index].endWorking = value.endWorking;
      oldItems[index].startWorking = value.startWorking;

      this.items = [...oldItems];
    },

    //update monthly status data API SW-0203
    changeMonthlyStatus(status) {
      let that = this;
      axios.defaults.headers.common = {
        Authorization: "Bearer " + that.token[1],
        "X-Api-Key": that.$store.state.headers["x-api-key"],
      };
      axios
        .put(that.$store.state.serverUrl + `attendance/monthly/v1/${that.monthlyId}`, {
          monthly_attendance_target: status,
        })
        .then(function (response) {
          if (response.status === 200) {
            that.confirmStatus = response.data.result.monthly_attendance_confirm_status;
          }
        })
        .catch(() => {});
    },

    //open checkbox option
    openCheckboxOption() {
      this.chkBoxOption = !this.chkBoxOption;
      const updateItem = this.items.filter((x) => x.isChecked);

      this.$store.dispatch("attendance/getUpdateItemInfo", updateItem);
    },
  },

  watch: {
    items: {
      handler(newValue) {
        this.isAllChecked = !newValue.some((x) => !x.isChecked);
        if (this.items.some((x) => x.isChecked)) {
          this.chkBoxOption = false;
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.row {
  font-family: "Roboto";
  color: #000000;
}
.btn {
  border: none;
  width: 24px;
  height: 24px;
}
.back-btn {
  background: url("../assets/back-icon.png");
  margin-left: 15px;
}
.comment-btn {
  background: url("../assets/comment-icon.png");
  margin-right: 23px;
}
.download-btn {
  background: url("../assets/download-icon.png");
  margin-right: 17px;
}
.demo-avt {
  width: 123px;
  height: 122px;
  margin-top: 10px;
}
.total-table {
  margin-top: 10px;
}
.emp-number {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}
.months-list {
  width: 102px;
  background: #ffffff;
  border: 0.5px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  text-align: center;
  -webkit-appearance: none;
}
.request-apply {
  text-align: center;
  width: 121px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 3px;
  margin-right: 12px;
  padding: 6px 15px 4px 20px;
  margin-bottom: 0;
}
.confirm-apply-btn {
  color: #ffffff;
  width: 121px;
  padding: 0px 16px 0px 0;
  background: #126cf3;
  border-radius: 3px;
  border: none;
}
.confirm-apply-btn:disabled {
  background-color: #8f8c8c;
}
.check-icon {
  width: 1.9em;
  height: 1.9em;
  vertical-align: middle;
}
.reject-btn {
  color: #ff0000;
  border: 0.5px solid #ff0000;
  border-radius: 3px;
  width: 121px;
  background: none;
  margin-right: 10px;
}
.reject-btn:disabled {
  color: #8f8c8c;
  border: 0.5px solid #8f8c8c;
}
.total-info-table {
  border-spacing: 0;
  width: 100%;
}
.detail-table {
  margin: 10px 14px 0 24px;
  border: 0.5px solid #b6b1b1;
  overflow-y: auto;
  height: 71.5vh;
  width: 100%;
}
.edit-btn {
  background: url("../assets/edit-icon.png");
}
.behavior-history-btn {
  background: url("../assets/behavior-history-icon.png");
}
.daily-report-btn {
  background: url("../assets/daily-report-icon.png");
}
.b-table >>> th[aria-colindex="1"],
.b-table >>> td[aria-colindex="1"] {
  width: 65px;
  padding-right: 0;
  padding-left: 0.55rem;
}
.b-table >>> td[aria-colindex="1"] {
  height: 17px;
}
.b-table >>> th[aria-colindex="2"],
.b-table >>> th[aria-colindex="3"],
.b-table >>> th[aria-colindex="5"],
.b-table >>> th[aria-colindex="10"],
.b-table >>> th[aria-colindex="11"],
.b-table >>> th[aria-colindex="12"],
.b-table >>> th[aria-colindex="13"],
.b-table >>> th[aria-colindex="14"],
.b-table >>> th[aria-colindex="15"],
.b-table >>> th[aria-colindex="16"],
.b-table >>> td[aria-colindex="2"],
.b-table >>> td[aria-colindex="3"],
.b-table >>> td[aria-colindex="5"],
.b-table >>> td[aria-colindex="10"],
.b-table >>> td[aria-colindex="11"],
.b-table >>> td[aria-colindex="12"],
.b-table >>> td[aria-colindex="13"],
.b-table >>> td[aria-colindex="14"],
.b-table >>> td[aria-colindex="15"],
.b-table >>> td[aria-colindex="16"] {
  text-align: center;
}
.b-table >>> th {
  font-weight: normal;
  background: rgba(0, 0, 0, 0.03);
}
.chk-box {
  min-width: 19px;
  min-height: 19px;
  border: 2px solid #c4c4c4;
  border-radius: 5px;
  margin-right: 7.5px;
}
.chk-box-option {
  border: none;
  background: transparent;
}

/* width for scroll-y and height for scroll-x */
::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border: none;
  border-radius: 5px;
}

::-webkit-scrollbar-track-piece:start {
  top: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #c4c4c4;
}
</style>
