<template>
  <div class="info-tab">
    <b-card no-body>
      <b-tabs
        active-nav-item-class="font-weight-bold active-nav"
        content-class="mt-2"
        v-model="currentTab"
      >
        <b-tab
          title="勤怠編集"
          style="overflow-y: auto; height: 65vh"
          @click.prevent="attendanceDataFormat(infoTab.full_date)"
        >
          <div>
            <div class="row w-100 m-0">
              <div class="col p-0">
                <span style="font-weight: bold">{{ date }}({{ dayOfWeek }})の勤怠編集</span>
              </div>
            </div>
          </div>
          <div>
            <table class="timekeeping-tbl">
              <tbody>
                <tr>
                  <td class="table-col table-odd">出勤時刻</td>
                  <td class="table-col table-even">
                    <input
                      class="table-input"
                      v-model="checkIn"
                      @change="calculateWorkingTime"
                      @keypress="filterCharacter($event)"
                      maxlength="5"
                    />
                  </td>
                  <td class="table-col table-odd">退勤時刻</td>
                  <td class="table-col table-even">
                    <input
                      class="table-input"
                      v-model="checkOut"
                      @change="calculateWorkingTime"
                      @keypress="filterCharacter($event)"
                      maxlength="5"
                    />
                  </td>
                </tr>
                <tr v-if="breakDataDetail && breakDataDetail.length === 0">
                  <td class="table-col table-odd">休憩開始</td>
                  <td class="table-col table-even">
                    <input
                      class="table-input"
                      v-model="breakStart"
                      @keypress="filterCharacter($event)"
                      maxlength="5"
                    />
                  </td>
                  <td class="table-col table-odd">休憩終了</td>
                  <td class="table-col table-even">
                    <input
                      class="table-input"
                      v-model="breakEnd"
                      @keypress="filterCharacter($event)"
                      maxlength="5"
                    />
                  </td>
                </tr>
                <tr v-for="(item, idx) in breakDataDetail" :key="idx">
                  <td class="table-col table-odd">休憩{{ item.count }}開始</td>
                  <td class="table-col table-even">
                    <input
                      class="table-input"
                      v-model="item.break_start.started_at"
                      @keypress="filterCharacter($event)"
                      maxlength="5"
                    />
                  </td>
                  <td class="table-col table-odd">休憩{{ item.count }}終了</td>
                  <td class="table-col table-even">
                    <input
                      class="table-input"
                      v-model="item.break_end.ended_at"
                      @keypress="filterCharacter($event)"
                      maxlength="5"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="isError" class="text-danger" style="margin: 0 83px 22px 31px">
            終了時間は開始時間より後に設定して下さい。
          </div>
          <div v-if="infoTab">
            <table class="timekeeping-tbl" v-if="infoTab.detail_data">
              <tbody>
                <tr>
                  <td class="table-col table-count-odd">休憩時間</td>
                  <td class="table-col table-even">
                    {{ infoTab.detail_data.breakTime }}
                  </td>
                  <td class="table-col table-count-odd">所定内</td>
                  <td class="table-col table-even">
                    {{ infoTab.detail_data.scheduledWorkingHour }}
                  </td>
                </tr>
                <tr>
                  <td class="table-col table-count-odd">実働時間</td>
                  <td class="table-col table-even">
                    {{ infoTab.detail_data.actualWorkingTime }}
                  </td>
                  <td class="table-col table-count-odd">所定外</td>
                  <td class="table-col table-even">
                    {{ infoTab.detail_data.outsideWorkingTime }}
                  </td>
                </tr>
                <tr>
                  <td class="table-col table-count-odd">時間外</td>
                  <td class="table-col table-even">
                    {{ infoTab.detail_data.overtime }}
                  </td>
                  <td class="table-col table-count-odd">深夜</td>
                  <td class="table-col table-even">
                    {{ infoTab.detail_data.midnightTime }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="comment-div">
            <textarea
              class="text-comment"
              placeholder="コメントを記載してください"
              v-model="commentContent"
            ></textarea>
            <button class="update-btn" @click="onClickUpdate">更新</button>
          </div>
          <div v-if="emptyCommentError" class="text-danger" style="margin: 0 83px 0px 31px">
            コメントを入力してください。
          </div>
          <div class="dropdown-divider"></div>
          <div>
            <div>
              <span class="comment-box">修正履歴</span>
            </div>
            <div class="comment-tbl">
              <table style="width: 100%" v-if="comments">
                <tbody v-for="(item, idx) in comments" :key="idx">
                  <tr>
                    <td class="datetime-comment">
                      {{ item.updated_at }}&emsp;{{ item.first_name }}
                      {{ item.last_name }}さんによる修正
                    </td>
                  </tr>
                  <tr>
                    <td style="padding-left: 6px">
                      {{ item.content }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </b-tab>

        <b-tab title="行動履歴" @click.prevent="getDriverRegulationData">
          <div>
            <div class="row m-0 w-100">
              <div class="col p-0">
                <span style="font-weight: bold">{{ date }}({{ dayOfWeek }})の行動履歴</span>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-12">出発地点：{{ departFrom }}</div>
            </div>
            <div class="row m-0">
              <div class="col-6">目標地点：{{ travelHistories.destination }}</div>
              <div v-if="travelHistories.traffic_offense" class="col-6">
                違反回数： {{ travelHistories.traffic_offense.length }}回
              </div>
            </div>
            <div class="dropdown-divider"></div>
          </div>
          <div class="history-table">
            <table>
              <tbody>
                <tr v-for="(itemHistory, idx) in travelHistories.target" :key="idx">
                  <td
                    width="50px"
                    style="background-color: rgba(215, 244, 250, 0.43); text-align: center"
                  >
                    <img :src="getIcon(itemHistory.target)" />
                  </td>
                  <td width="70px" style="text-align: center">
                    {{ itemHistory.time }}
                  </td>
                  <td>{{ itemHistory.target }}<br />{{ itemHistory.address }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>

        <b-tab title="日報" @click.prevent="getDailyReportData">
          <div>
            <div class="row m-0 w-100">
              <div class="col p-0">
                <span style="font-weight: bold">{{ date }}({{ dayOfWeek }})の勤怠編集</span>
              </div>
            </div>
            <daily-report-info />
          </div>
        </b-tab>

        <template #tabs-end>
          <b-nav-item class="close-item" role="presentation" @click.prevent="closeTab">
            <b>
              <b-icon-x></b-icon-x>
            </b>
          </b-nav-item>
        </template>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import announceIcon from "./../../assets/announce-icon.png";
import workIcon from "./../../assets/work-icon.png";
import breakTimeIcon from "./../../assets/break-time-icon.png";
import suddenStartIcon from "./../../assets/sudden-start-icon.png";
import axios from "axios";
import Cookies from "js-cookie";
import { Loader } from "@googlemaps/js-api-loader";
import dailyReportInfo from "../AttendanceManage/DailyReportInfo.vue";

export default {
  computed: {
    //get tab information from attendance table
    ...mapGetters({
      infoTab: "attendance/infoTab",
    }),

    currentTab: {
      get: function () {
        return this.selectedTab || this.chosenTab;
      },
      set: function (newValue) {
        this.chosenTab = newValue;
      },
    },
  },
  mounted() {
    this.userId = this.$route.query.id;
    this.checkIn = this.infoTab?.detail_data?.startWorking;
    this.checkOut = this.infoTab?.detail_data?.endWorking;
    this.dayOfWeek = this.infoTab?.day_of_week;
    this.date = this.infoTab?.date;
  },

  components: { dailyReportInfo },

  watch: {
    infoTab: {
      handler() {
        this.commentContent = null;
        this.checkIn = this.infoTab.detail_data?.startWorking;
        this.checkOut = this.infoTab.detail_data?.endWorking;
        this.dayOfWeek = this.infoTab.day_of_week;
        this.date = this.infoTab.date;
      },
    },
  },

  props: {
    selectedTab: Number,
  },

  data() {
    return {
      announceIcon,
      workIcon,
      breakTimeIcon,
      suddenStartIcon,
      commentContent: null,
      checkIn: null,
      checkOut: null,
      dayOfWeek: null,
      date: null,
      breakStart: "-",
      breakEnd: "-",
      travelHistories: [],
      breakDataDetail: null,
      attendanceData: null,
      comments: [],
      token: Cookies.get("token").split("|"),
      userId: null,
      dailyId: null,
      departFrom: null,
      isError: false,
      emptyCommentError: false,
      chosenTab: 0,
    };
  },

  methods: {
    //auto enter ":" when user enter 2 character on input time
    filterCharacter(e) {
      if (e.target.value.length === 2) {
        e.target.value = e.target.value + ":";
      }
    },

    // get daily report data SW-0206
    async getDailyReportData() {
      axios.defaults.headers.common = {
        Authorization: "Bearer " + this.token[1],
        "X-Api-Key": this.$store.state.headers["x-api-key"],
      };
      const { data } = await axios.get(this.$store.state.serverUrl + "attendance/report/v1", {
        params: {
          tenant_id: Cookies.get("tenantId"),
          user_id: this.userId,
          date: this.infoTab?.full_date,
        },
      });

      //
      this.$store.dispatch("attendance/getDailyReportData", data.result);
    },

    //get Driver Regulation Data SW-0103
    async getDriverRegulationData() {
      this.travelHistories = [];
      let that = this;
      axios.defaults.headers.common = {
        Authorization: "Bearer " + that.token[1],
        "X-Api-Key": that.$store.state.headers["x-api-key"],
      };
      await axios
        .get(this.$store.state.serverUrl + "drivers-regulation/v1", {
          params: {
            tenant_id: Cookies.get("tenantId"),
            user_id: that.userId,
            start_date: that.infoTab.full_date,
            end_date: that.infoTab.full_date,
          },
        })
        .then(async function (response) {
          //check if get data success
          if (response.status === 200) {
            const data = response.data.result;
            //get data of origin place (出発地点)
            if (data.departure_from) {
              let originGeometry = {
                lat: parseFloat(Number(data.departure_from.latitude)),
                lng: parseFloat(Number(data.departure_from.longitude)),
              };

              // get address name from latitude and longitude(convert origin place to address name)
              that.getGeocode(originGeometry);
            }
            //get statuses list
            const statuses = data.statuses;

            let formatStatus = [];
            if (statuses.length > 0) {
              //get address name of each status location
              const getAddress = statuses.map(async (x) => {
                const address = await that.getLocationName({
                  lat: Number(x.latitude),
                  lng: Number(x.longitude),
                });
                return { ...x, address: address };
              });

              //format data address name
              const newStatuses = await Promise.all(getAddress);

              formatStatus = newStatuses.map((x) => {
                return {
                  time: x.time,
                  target: x.target_name,
                  address: x.address,
                };
              });
            }

            // get violation
            const violations = data.traffic_offense;

            let formatViolation = violations.map((x) => {
              return { time: x.time, target: x.name, address: null };
            });

            let subData = [];
            formatStatus.forEach((item) => subData.push(item));
            formatViolation.forEach((item) => subData.push(item));

            //add data to travel histories array
            that.travelHistories = {
              ...data,
              // order array by time
              target: subData.sort((a, b) => {
                return new Date("1970-01-01 " + a.time) - new Date("1970-01-01 " + b.time);
              }),
            };
          }
        })
        .catch(() => {});
    },

    //convert location to address
    getGeocode(location) {
      this.departFrom = null;
      const loader = new Loader({
        apiKey: this.$store.state.googleApiKey,
        version: "places",
        region: "JP",
        language: "ja",
      });

      loader.load().then(() => {
        const geocoder = new window.google.maps.Geocoder();
        //convert location geometry to address
        if (location !== null) {
          let that = this;
          geocoder.geocode({ location: location }, function (results, status) {
            if (status === "OK") {
              that.departFrom = results[0].formatted_address.split("、")[1];
            } else {
              if (status === "OVER_QUERY_LIMIT") {
                setTimeout(function () {
                  that.getGeocode(location);
                }, 200);
              }
              if (status === "ZERO_RESULTS") {
                that.departFrom = null;
              }
            }
          });
        }
      });
    },

    //get address information of each status data
    getLocationName(location) {
      return new Promise((resolve) => {
        const loader = new Loader({
          apiKey: this.$store.state.googleApiKey,
          version: "places",
          region: "JP",
          language: "ja",
        });
        loader.load().then(() => {
          let that = this;
          const geocoder = new window.google.maps.Geocoder();
          geocoder.geocode({ location: location }, function (results, status) {
            if (status === "OK") {
              resolve(results[0].formatted_address.split("、")[1]);
            } else {
              //when status return is OVER_QUERY_LIMIT, callback to getLocationName function with timeout
              if (status === "OVER_QUERY_LIMIT") {
                setTimeout(function () {
                  resolve(that.getLocationName(location));
                }, 200);
              }
              if (status === "ZERO_RESULTS") {
                resolve(null);
              }
            }
          });
        });
      });
    },

    //format data
    async attendanceDataFormat(date) {
      // get daily attendance data
      axios.defaults.headers.common = {
        Authorization: "Bearer " + this.token[1],
        "X-Api-Key": this.$store.state.headers["x-api-key"],
      };
      const { data } = await axios.get(this.$store.state.serverUrl + "attendance/daily/v1", {
        params: {
          user_id: this.userId,
          date: date,
          tenant_id: Cookies.get("tenantId"),
        },
      });

      this.attendanceData = data.result?.attendances[0];

      this.breakDataDetail = this.attendanceData?.break_time_detail;
      this.comments = [];

      this.attendanceData?.comment.forEach((item) => {
        let subData = {
          updated_at: this.formatDatetime(item.updated_at),
          first_name: item.first_name,
          last_name: item.last_name,
          content: item.content,
        };

        this.comments.push(subData);
      });
    },

    //format date data
    formatDatetime(value) {
      if (value !== null) {
        const date = new Date(value);

        return date.toLocaleString("ja-JP");
      } else {
        return null;
      }
    },

    //set icon for driver active status
    getIcon(status) {
      switch (status) {
        case "乗務前点呼":
        case "中間点呼":
        case "乗務後点呼":
          return announceIcon;

        case "出勤":
        case "退勤":
          return workIcon;

        case "急加速":
        case "急減速":
        case "速度超過":
        case "異常な衝撃":
          return suddenStartIcon;

        default:
          return breakTimeIcon;
      }
    },

    //when click close button on tab
    async closeTab() {
      this.$emit("onClose");
    },

    // calculate time
    calculateWorkingTime() {
      //calculate working time
      if (this.checkIn && this.checkOut) {
        let start = new Date("1970-01-01 " + this.checkIn);
        let end = new Date("1970-01-01 " + this.checkOut);
        if (start >= end) {
          return (this.isError = true);
        } else {
          this.isError = false;
        }

        this.infoTab.detail_data.actualWorkingTime = Number(
          (
            (end - start) / 3600000 -
            this.infoTab.detail_data.breakTime -
            this.infoTab.detail_data.napTime / 60
          ).toFixed(2)
        );
      }
    },

    //when click button update in comment box
    onClickUpdate() {
      let attendances = [];
      let breakDetail = [];
      this.isError = false;
      this.emptyCommentError = false;

      //get break information to update
      if (this.breakDataDetail?.length > 0) {
        var countBreakTime = 0;
        this.breakDataDetail.forEach((item) => {
          let dataUpdate = {
            break_start: {
              id: null,
              started_at: null,
              is_deleted: null,
            },
            break_end: {
              id: null,
              ended_at: null,
              is_deleted: null,
            },
          };
          dataUpdate.break_start.id = item.break_start.id;
          dataUpdate.break_start.started_at = item.break_start.started_at;
          dataUpdate.break_start.is_deleted = false;
          dataUpdate.break_end.id = item.break_end.id;
          dataUpdate.break_end.ended_at = item.break_end.ended_at;
          dataUpdate.break_end.is_deleted = false;

          //calculate break time after update
          let start = new Date("1970-01-01 " + item.break_start.started_at);
          let end = new Date("1970-01-01 " + item.break_end.ended_at);
          if (start >= end) {
            return (this.error = true);
          }

          countBreakTime += +((end - start) / 3600000).toFixed(2);
          breakDetail.push(dataUpdate);
        });

        if (this.isError) {
          return this.isError;
        }
        this.infoTab.detail_data.breakTime = countBreakTime;
      } else if (this.breakStart !== "-" && this.breakEnd !== "-") {
        let dataUpdate = {
          break_start: {
            id: null,
            started_at: null,
            is_deleted: null,
          },
          break_end: {
            id: null,
            ended_at: null,
            is_deleted: null,
          },
        };
        dataUpdate.break_start.id = null;
        dataUpdate.break_start.started_at = this.breakStart;
        dataUpdate.break_start.is_deleted = false;
        dataUpdate.break_end.id = null;
        dataUpdate.break_end.ended_at = this.breakEnd;
        dataUpdate.break_end.is_deleted = false;

        //calculate break time after update
        const startHours = Number(this.breakStart.split(":")[0]);
        const startMinutes = Number(this.breakStart.split(":")[1]);
        let start = startHours * 60 + startMinutes;

        const endHours = Number(this.breakEnd.split(":")[0]);
        const endMinutes = Number(this.breakEnd.split(":")[1]);
        let end = endHours * 60 + endMinutes;
        if (start >= end) {
          return (this.error = true);
        }

        countBreakTime = Number(((end - start) / 60).toFixed(2));
        breakDetail.push(dataUpdate);

        if (this.isError) {
          return this.isError;
        }
        this.infoTab.detail_data.breakTime = countBreakTime;
      } else {
        breakDetail = null;
      }

      if (this.isEmpty(this.commentContent)) {
        return (this.emptyCommentError = true);
      }

      //format data to update
      let data = {
        date: this.infoTab.full_date,
        work_schedule_id: this.attendanceData.work_schedule_id,
        work_type: this.attendanceData.work_started_at,
        work_started_at: this.checkIn,
        work_ended_at: this.checkOut,
        break_time: Number((this.infoTab.detail_data.breakTime * 60).toFixed(0)),
        nap_time: Number((this.infoTab.detail_data.napTime * 60).toFixed(0)),
        wait_time: Number((this.infoTab.detail_data.awaitTime * 60).toFixed(0)),
        break_time_detail: breakDetail,
        nap_time_detail: null,
        wait_time_detail: null,
        content: this.commentContent,
      };
      attendances.push(data);

      this.dailyId = this.infoTab.detail_data.id;
      axios.defaults.headers.common = {
        Authorization: "Bearer " + this.token[1],
        "X-Api-Key": this.$store.state.headers["x-api-key"],
      };

      let that = this;
      axios
        .put(this.$store.state.serverUrl + `attendance/daily/v1/${this.dailyId}`, {
          user_id: this.userId,
          attendances: attendances,
        })
        .then(function (response) {
          if (response.status === 200) {
            that.comments.unshift({
              updated_at: new Date().toLocaleString("ja-JP"),
              first_name: "",
              last_name: "",
              content: that.commentContent,
            });
            that.commentContent = null;
          }
        })
        .catch(() => {});
      this.$emit("onUpdate", this.infoTab.detail_data);
    },

    // format data to BC API update
    getDataUpdate() {
      let data = {
        times: [],
        statuses: [],
        targets: [],
      };
      // get data of checkin time
      if (!this.isEmpty(this.checkIn)) {
        data.statuses.push("S02");
        data.targets.push("T01");
        data.times.push(this.checkIn);
      }

      // get data of checkout time
      if (!this.isEmpty(this.checkOut)) {
        data.statuses.push("S01");
        data.targets.push("T02");
        data.times.push(this.checkOut);
      }

      // get data of break start time
      if (this.breakStart !== "-") {
        data.statuses.push("R02");
        data.targets.push("B01");
        data.times.push(this.breakStart);
      } else {
        if (this.breakDataDetail) {
          this.breakDataDetail.forEach((item) => {
            data.statuses.push("R02");
            data.targets.push("B01");
            data.times.push(item.break_start.started_at);
          });
        }
      }

      // get data of break end time
      if (this.breakEnd !== "-") {
        data.statuses.push("R01");
        data.targets.push("B02");
        data.times.push(this.breakEnd);
      } else {
        if (this.breakDataDetail) {
          this.breakDataDetail.forEach((item) => {
            data.statuses.push("R01");
            data.targets.push("B02");
            data.times.push(item.break_end.ended_at);
          });
        }
      }

      return data;
    },

    isEmpty(value) {
      return value === "" || value === undefined || value === null;
    },
  },
};
</script>

<style scoped>
.info-tab {
  position: absolute;
  right: 0;
  line-height: 2.2;
  font-size: 14px;
  width: 550px;
  margin-top: 10px;
}
.card {
  border: none;
  border-top: 0.5px solid rgba(0, 0, 0, 0.125);
  border-right: 0.5px solid rgba(0, 0, 0, 0.125);
}
.dropdown-divider {
  border-top: 0.5px solid #b6b1b1;
  margin-right: 26px;
}
.history-table table tr:first-child td:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

/* bottom-left border-radius */
.history-table table tr:last-child td:first-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.col span {
  font-size: 16px;
}
.tabs >>> .active-nav {
  border-bottom: 7px solid #8bdff9 !important;
}
.tabs >>> .nav-link {
  width: 170px;
  color: #000000;
  text-align: center;
  border-radius: 0;
  font-size: 16px;
}
.tabs >>> .tab-content {
  padding-left: 25px;
}
.tabs >>> .close-item .nav-link {
  width: 38px;
  padding-left: 0;
  padding-right: 0;
}
.nav-link .b-icon.bi {
  font-size: 200%;
}
.history-table {
  margin: 27px 0 0 31px;
  overflow-y: auto;
  height: 50vh;
}
.history-table table > tbody {
  text-align: left;
  vertical-align: baseline;
}
/* width for scroll-y and height for scroll-x */
::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border: none;
  border-radius: 5px;
}

::-webkit-scrollbar-track-piece:start {
  top: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #c4c4c4;
}

.timekeeping-tbl {
  border-collapse: separate;
  margin: 0 83px 22px 31px;
  border-spacing: 0;
}
/* top-left border-radius */
.timekeeping-tbl tr:first-child td:first-child {
  border-top-left-radius: 5px;
}

/* top-right border-radius */
.timekeeping-tbl tr:first-child td:last-child {
  border-top-right-radius: 5px;
}

/* bottom-left border-radius */
.timekeeping-tbl tr:last-child td:first-child {
  border-bottom-left-radius: 5px;
}

/* bottom-right border-radius */
.timekeeping-tbl tr:last-child td:last-child {
  border-bottom-right-radius: 5px;
}

.table-col {
  width: 109px;
  border: 1px solid #b6b1b1;
}
.table-odd {
  background: #d7f4fa;
  padding-left: 10px;
}
.table-even {
  text-align: center;
}
.table-input {
  width: 100%;
  text-align: center;
  border: none;
}
.table-input:focus {
  outline: none;
}
.table-count-odd {
  background: #e7f1f3;
  padding-left: 10px;
}
.comment-div {
  margin-bottom: 19px;
}
.text-comment {
  margin-left: 31px;
  width: 302px;
  height: 95px;
  border: 0.5px solid #c4c4c4;
  border-radius: 5px;
  padding-left: 8px;
  vertical-align: bottom;
  resize: none;
}

.update-btn {
  text-align: center;
  width: 93px;
  margin-left: 15px;
  background: #126cf3;
  border-radius: 5px;
  border: none;
  color: #ffffff;
}
.comment-box {
  margin-left: 31px;
}
.comment-tbl {
  width: 469px;
  margin: 0 0 5px 31px;
  height: 12vh;
  overflow: auto;
  border: 0.5px solid #c4c4c4;
  border-radius: 5px;
}
.comment-tbl > tbody > tr {
  word-wrap: break-word;
  word-break: break-word;
}
.datetime-comment {
  background-color: #f1f4f4;
  padding-left: 6px;
}
</style>
