<template>
  <table class="total-info-table" v-if="totalData">
    <tbody>
      <tr>
        <td class="table-col table-head">所定/出勤日数</td>
        <td class="table-col table-value">
          {{ totalData.standard_work_days }}/{{ totalData.work_days }}
        </td>
        <td class="table-col table-head">残業時間</td>
        <td class="table-col table-value">{{ totalData.overtime }}</td>
        <td class="table-col table-head">所定/実働時間</td>
        <td class="table-col table-value">
          {{ totalData.standard_work_hours }}/{{ totalData.work_hours }}
        </td>
        <td class="table-col table-head">休出日数</td>
        <td class="table-col table-value">{{ totalData.work_holidays }}</td>
      </tr>
      <tr>
        <td class="table-col table-head">平日深夜外</td>
        <td class="table-col table-value">{{ totalData.normal_midnight_outside_hours }}</td>
        <td class="table-col table-head">法定休深夜外</td>
        <td class="table-col table-value">{{ totalData.legal_holiday_midnight_outside_hours }}</td>
        <td class="table-col table-head">所定休深夜外</td>
        <td class="table-col table-value">
          {{ totalData.schedule_holiday_midnight_outside_hours }}
        </td>
        <td class="table-col table-head">休暇日数</td>
        <td class="table-col table-value">{{ totalData.vacation_days }}</td>
      </tr>
      <tr>
        <td class="table-col table-head">平日深夜</td>
        <td class="table-col table-value">{{ totalData.normal_midnight_hours }}</td>
        <td class="table-col table-head">法定休深夜</td>
        <td class="table-col table-value">{{ totalData.legal_holiday_midnight_hours }}</td>
        <td class="table-col table-head">所定休深夜</td>
        <td class="table-col table-value">{{ totalData.schedule_holiday_midnight_hours }}</td>
        <td class="table-col table-head"></td>
        <td class="table-col table-value"></td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      totalData: "attendance/totalData",
    }),
  },
};
</script>

<style scoped>
table {
  border-collapse: separate;
}
/* top-left border-radius */
table tr:first-child td:first-child {
  border-top-left-radius: 10px;
}

/* top-right border-radius */
table tr:first-child td:last-child {
  border-top-right-radius: 10px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}
.table-col {
  width: 177px;
  border: 1px solid #b6b1b1;
}
.table-head {
  background: #d7f4fa;
  padding-left: 10px;
}
.table-value {
  text-align: center;
}
</style>
