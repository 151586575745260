<template>
  <div class="row w-100 m-0 daily-report">
    <!-- 使用車両 -->
    <div class="row w-100 m-0">
      <div class="col-6 report-detail label-color">使用車両</div>
      <div class="col-6 report-detail">{{ vehicle }}</div>
    </div>

    <!-- 乗務前点呼 -->
    <div class="row w-100 m-0" v-for="(preRoll, idx) in preRollList" :key="idx">
      <div class="row w-100 m-0">
        <div class="col-6 report-detail label-color" v-if="preRollList.length === 1">
          乗務前点呼
        </div>
        <div class="col-6 report-detail label-color" v-else>乗務前点呼{{ idx + 1 }}</div>
        <div class="col-6 report-detail">{{ preRoll.status }}</div>
      </div>
      <div class="row w-100 m-0">
        <div class="col-1 label-color"></div>
        <div class="col-5 report-detail sub-label-color">実施時間</div>
        <div class="col-6 report-detail">{{ preRoll.time }}</div>
      </div>
      <div class="row w-100 m-0">
        <div class="col-1 label-color"></div>
        <div class="col-5 report-detail sub-label-color">実施方式</div>
        <div class="col-6 report-detail">{{ preRoll.method }}</div>
      </div>
      <div class="row w-100 m-0">
        <div class="col-1 label-color"></div>
        <div class="col-5 report-detail sub-label-color">検知器使用有無</div>
        <div class="col-6 report-detail">{{ preRoll.detector }}</div>
      </div>
      <div class="row w-100 m-0">
        <div class="col-1 label-color"></div>
        <div class="col-5 report-detail sub-label-color">酒器帯び有無</div>
        <div class="col-6 report-detail">{{ preRoll.intoxicated }}</div>
      </div>
    </div>

    <!-- 同乗者 -->
    <div class="row w-100 m-0">
      <div class="row w-100 m-0">
        <div class="col-6 report-detail label-color">同乗者</div>
        <div class="col-6 report-detail">{{ isHavePassenger }}</div>
      </div>
      <div class="row w-100 m-0" v-for="(passenger, index) in passengerList" :key="index">
        <div class="col-1 label-color"></div>
        <div class="col-5 report-detail sub-label-color">同乗者{{ index + 1 }}</div>
        <div class="col-6 report-detail">{{ passenger.passenger_name }}</div>
      </div>
    </div>

    <!-- 交代運転 -->
    <div class="row w-100 m-0">
      <div class="row w-100 m-0">
        <div class="col-6 report-detail label-color">交代運転</div>
        <div class="col-6 report-detail" v-if="alternate !== '-'">あり</div>
        <div class="col-6 report-detail" v-else>-</div>
      </div>
      <div class="row w-100 m-0">
        <div class="col-1 label-color"></div>
        <div class="col-5 report-detail sub-label-color">交代運転車名</div>
        <div class="col-6 report-detail">{{ alternate }}</div>
      </div>
    </div>

    <!-- 走行情報 -->
    <div class="row w-100 m-0">
      <div class="row w-100 m-0">
        <div class="col-6 report-detail label-color">走行情報</div>
        <div class="col-6 report-detail">
          <span>{{ drivingInfo }}</span>
          <span class="float-right">km</span>
        </div>
      </div>
      <div class="row w-100 m-0">
        <div class="col-1 label-color"></div>
        <div class="col-5 report-detail sub-label-color">出庫メーター</div>
        <div class="col-6 report-detail">
          {{ deliveryMeter }}
          <span class="float-right">km</span>
        </div>
      </div>
      <div class="row w-100 m-0">
        <div class="col-1 label-color"></div>
        <div class="col-5 report-detail sub-label-color">帰庫メーター</div>
        <div class="col-6 report-detail">
          {{ returnMeter }}
          <span class="float-right">km</span>
        </div>
      </div>
    </div>

    <!-- 費用 -->
    <div class="row w-100 m-0">
      <div class="row w-100 m-0">
        <div class="col-6 report-detail label-color">費用</div>
        <div class="col-6 report-detail">
          {{ totalCost }}
          <span class="float-right">円</span>
        </div>
      </div>
      <div class="row w-100 m-0">
        <div class="col-1 label-color"></div>
        <div class="col-5 report-detail sub-label-color">燃料代</div>
        <div class="col-6 report-detail">
          {{ fuelCost }}
          <span class="float-right">円</span>
        </div>
      </div>
      <div class="row w-100 m-0">
        <div class="col-1 label-color"></div>
        <div class="col-5 report-detail sub-label-color">高速料金合計</div>
        <div class="col-6 report-detail">
          {{ tollCost }}
          <span class="float-right">円</span>
        </div>
      </div>
      <div class="row w-100 m-0">
        <div class="col-1 label-color"></div>
        <div class="col-5 report-detail sub-label-color">その他</div>
        <div class="col-6 report-detail">
          {{ otherCost }}
          <span class="float-right">円</span>
        </div>
      </div>
    </div>

    <!-- 高速道路 -->
    <div class="row m-0 w-100" v-for="(highway, idx) in tollList" :key="'highway' + idx">
      <div class="row w-100 m-0">
        <div class="col-6 report-detail label-color" v-if="tollList.length === 1">高速道路</div>
        <div class="col-6 report-detail label-color" v-else>高速道路{{ idx + 1 }}</div>
        <div class="col-6 report-detail">
          {{ highway.toll_fees }}
          <span class="float-right">円</span>
        </div>
      </div>
      <div class="row w-100 m-0">
        <div class="col-1 label-color"></div>
        <div class="col-5 report-detail sub-label-color">高速道路入口</div>
        <div class="col-6 report-detail">{{ highway.entry }}</div>
      </div>
      <div class="row w-100 m-0">
        <div class="col-1 label-color"></div>
        <div class="col-5 report-detail sub-label-color">高速道路出口</div>
        <div class="col-6 report-detail">{{ highway.exit }}</div>
      </div>
    </div>

    <!-- 配送地点 -->
    <div class="row w-100 m-0" v-for="(route, idx) in deliveryRoute" :key="'route' + idx">
      <div class="row w-100 m-0">
        <div class="col-6 report-detail label-color" v-if="deliveryRoute.length === 1">
          配送地点
        </div>
        <div class="col-6 report-detail label-color" v-else>配送地点{{ idx + 1 }}</div>
        <div class="col-6 report-detail">{{ route.destination }}</div>
      </div>
      <div class="row w-100 m-0">
        <div class="col-1 label-color"></div>
        <div class="col-5 report-detail sub-label-color">品名</div>
        <div class="col-6 report-detail">{{ route.product_name }}</div>
      </div>
      <div class="row w-100 m-0">
        <div class="col-1 label-color"></div>
        <div class="col-5 report-detail sub-label-color">重量(個数)</div>
        <div class="col-6 report-detail">{{ route.unit }}</div>
      </div>
      <div class="row w-100 m-0">
        <div class="col-1 label-color"></div>
        <div class="col-5 report-detail sub-label-color">積載状況</div>
        <div class="col-6 report-detail">{{ route.loading_status }}</div>
      </div>
      <div class="row w-100 m-0">
        <div class="col-1 label-color"></div>
        <div class="col-5 report-detail sub-label-color">備考</div>
        <div class="col-6 report-detail">{{ route.note }}</div>
      </div>
    </div>

    <!-- 中間点呼 -->
    <div class="row w-100 p-0 m-0" v-for="(midRoll, idx) in midRollList" :key="'midRoll' + idx">
      <div class="row w-100 m-0">
        <div class="col-6 report-detail label-color" v-if="midRollList.length === 1">中間点呼</div>
        <div class="col-6 report-detail label-color" v-else>中間点呼{{ idx + 1 }}</div>
        <div class="col-6 report-detail">{{ midRoll.status }}</div>
      </div>
      <div class="row w-100 m-0">
        <div class="col-1 label-color"></div>
        <div class="col-5 report-detail sub-label-color">実施時間</div>
        <div class="col-6 report-detail">{{ midRoll.time }}</div>
      </div>
      <div class="row w-100 m-0">
        <div class="col-1 label-color"></div>
        <div class="col-5 report-detail sub-label-color">実施方式</div>
        <div class="col-6 report-detail">{{ midRoll.method }}</div>
      </div>
      <div class="row w-100 m-0">
        <div class="col-1 label-color"></div>
        <div class="col-5 report-detail sub-label-color">検知器使用有無</div>
        <div class="col-6 report-detail">{{ midRoll.detector }}</div>
      </div>
      <div class="row w-100 m-0">
        <div class="col-1 label-color"></div>
        <div class="col-5 report-detail sub-label-color">酒器帯び有無</div>
        <div class="col-6 report-detail">{{ midRoll.intoxicated }}</div>
      </div>
    </div>

    <!-- 乗務後点呼 -->
    <div class="row w-100 m-0" v-for="(afterRoll, idx) in afterRollList" :key="'afterRoll' + idx">
      <div class="row w-100 m-0">
        <div class="col-6 report-detail label-color" v-if="afterRollList.length === 1">
          乗務後点呼
        </div>
        <div class="col-6 report-detail label-color" v-else>乗務後点呼{{ idx + 1 }}</div>
        <div class="col-6 report-detail">{{ afterRoll.status }}</div>
      </div>
      <div class="row w-100 m-0">
        <div class="col-1 label-color"></div>
        <div class="col-5 report-detail sub-label-color">実施時間</div>
        <div class="col-6 report-detail">{{ afterRoll.time }}</div>
      </div>
      <div class="row w-100 m-0">
        <div class="col-1 label-color"></div>
        <div class="col-5 report-detail sub-label-color">実施方式</div>
        <div class="col-6 report-detail">{{ afterRoll.method }}</div>
      </div>
      <div class="row w-100 m-0">
        <div class="col-1 label-color"></div>
        <div class="col-5 report-detail sub-label-color">検知器使用有無</div>
        <div class="col-6 report-detail">{{ afterRoll.detector }}</div>
      </div>
      <div class="row w-100 m-0">
        <div class="col-1 label-color"></div>
        <div class="col-5 report-detail sub-label-color">酒器帯び有無</div>
        <div class="col-6 report-detail">{{ afterRoll.intoxicated }}</div>
      </div>
    </div>

    <!-- 報告事項 -->
    <div class="row w-100 m-0">
      <div class="col-6 report-detail label-color">報告事項</div>
      <div class="col-6 report-detail">{{ report }}</div>
    </div>

    <!-- 指導・連絡事項 -->
    <div class="row w-100 m-0">
      <div class="col-6 report-detail label-color">指導・連絡事項</div>
      <div class="col-6 report-detail">{{ matters }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    //get tab information from attendance table
    ...mapGetters({
      reportData: "attendance/dailyReportData",
    }),
  },

  data() {
    return {
      vehicle: "-",
      preRollList: null,
      passengerList: null,
      alternate: "-",
      drivingInfo: null,
      deliveryMeter: null,
      returnMeter: null,
      totalCost: null,
      fuelCost: null,
      tollCost: null,
      otherCost: null,
      tollList: null,
      deliveryRoute: [],
      midRollList: null,
      afterRollList: null,
      report: null,
      matters: null,
      isHavePassenger: "-",
    };
  },

  methods: {
    setData() {
      if (this.reportData && Object.keys(this.reportData).length !== 0) {
        //set data vehicle
        if (this.reportData.vehicle) {
          this.vehicle =
            this.reportData.vehicle?.vehicle_name +
            "(" +
            this.reportData.vehicle?.vehicle_license_number +
            ")";
        }

        // set data pre roll call
        if (this.reportData.pre_roll?.length > 0) {
          this.preRollList = this.reportData.pre_roll.map((x) => {
            return {
              ...x,
              status: "実施済み",
              detector: x.is_detector_used === "1" ? "有" : "無",
              intoxicated: x.is_intoxicated === "0" ? "無" : "有",
            };
          });
        } else {
          this.preRollList = [
            {
              status: "-",
              time: "-",
              method: "-",
              detector: "-",
              intoxicated: "-",
            },
          ];
        }

        // set data passenger
        if (this.reportData.passengers?.length > 0) {
          this.isHavePassenger = "あり";
          this.passengerList = this.reportData.passengers.map((x) => {
            return { passenger_name: x.first_name + x.last_name };
          });
        } else {
          this.passengerList = [
            {
              passenger_name: "-",
            },
          ];
        }

        // set data alternate driver
        if (this.reportData.alternate_driver !== null) {
          this.alternate =
            this.reportData.alternate_driver.first_name +
            this.reportData.alternate_driver.last_name;
        }

        // set data driving
        this.deliveryMeter = this.reportData.driving_data.departure_meter;
        this.returnMeter = this.reportData.driving_data.arrival_meter;
        this.drivingInfo = this.returnMeter - this.deliveryMeter;

        // set data cost
        this.fuelCost = this.reportData.fee.fuel_fees;
        this.otherCost = this.reportData.fee.other_fees;
        this.tollCost = this.reportData.fee.tolls?.reduce((a, b) => a + b.toll_fees, 0);

        this.totalCost = this.fuelCost + this.otherCost + this.tollCost;

        // set highway data
        if (this.reportData.fee.tolls?.length > 0) {
          this.tollList = this.reportData.fee.tolls;
        } else {
          this.tollList = [
            {
              entry: "-",
              exit: "-",
              toll_fees: "-",
            },
          ];
        }

        // set delivery route data
        if (this.reportData.route?.length > 0) {
          this.deliveryRoute = [];
          this.reportData.route.forEach((element) => {
            let data = {
              destination: element.destination,
              product_name: element.product ? element.product : "-",
              unit: element.unit ? element.unit : "-",
              loading_status: element.product_status ? element.product_status : "-",
              note: element.note,
            };

            this.deliveryRoute.push(data);
          });
        } else {
          this.deliveryRoute = [
            {
              destination: "-",
              product_name: "-",
              unit: "-",
              loading_status: "-",
              note: "-",
            },
          ];
        }

        // set data mid roll call
        if (this.reportData.mid_roll?.length > 0) {
          this.midRollList = this.reportData.mid_roll.map((x) => {
            return {
              ...x,
              status: "実施済み",
              detector: x.is_detector_used === "1" ? "有" : "無",
              intoxicated: x.is_intoxicated === "0" ? "無" : "有",
            };
          });
        } else {
          this.midRollList = [
            {
              status: "-",
              time: "-",
              method: "-",
              detector: "-",
              intoxicated: "-",
            },
          ];
        }

        // set data after roll call
        if (this.reportData.after_roll?.length > 0) {
          this.afterRollList = this.reportData.after_roll.map((x) => {
            return {
              ...x,
              status: "実施済み",
              detector: x.is_detector_used === "1" ? "有" : "無",
              intoxicated: x.is_intoxicated === "0" ? "無" : "有",
            };
          });
        } else {
          this.afterRollList = [
            {
              status: "-",
              time: "-",
              method: "-",
              detector: "-",
              intoxicated: "-",
            },
          ];
        }

        this.report = this.reportData.report;
        this.matters = this.reportData.matters;
      } else {
        this.vehicle = "-";
        this.isHavePassenger = "-";
        this.alternate = "-";
        this.drivingInfo = null;
        this.deliveryMeter = null;
        this.returnMeter = null;
        this.totalCost = null;
        this.fuelCost = null;
        this.tollCost = null;
        this.otherCost = null;
        this.report = null;
        this.matters = null;
        // data pre roll call
        this.preRollList = [
          {
            status: "-",
            time: "-",
            method: "-",
            detector: "-",
            intoxicated: "-",
          },
        ];

        // data passenger
        this.passengerList = [
          {
            passenger_name: "-",
          },
        ];

        // highway data
        this.tollList = [
          {
            entry: "-",
            exit: "-",
            tolls: "-",
          },
        ];

        // delivery route data
        this.deliveryRoute = [
          {
            destination: "-",
            product_name: "-",
            unit: "-",
            loading_status: "-",
            note: "-",
          },
        ];

        // data mid roll call
        this.midRollList = [
          {
            status: "-",
            time: "-",
            method: "-",
            detector: "-",
            intoxicated: "-",
          },
        ];

        // data after roll call
        this.afterRollList = [
          {
            status: "-",
            time: "-",
            method: "-",
            detector: "-",
            intoxicated: "-",
          },
        ];
      }
    },
  },

  mounted() {
    this.setData();
  },

  watch: {
    reportData: {
      handler() {
        this.setData();
      },
    },
  },
};
</script>

<style scoped>
.daily-report {
  overflow-y: auto;
  height: 60vh;
  border-right: none;
  font-size: 14px;
}

.report-detail {
  border-top: 0.5px solid #b6b1b1;
  border-left: 0.5px solid #b6b1b1;
  border-right: none;
}

.label-color {
  background: #95c3cc;
  color: #ffffff;
}

.sub-label-color {
  background: #e7f1f3;
}
</style>
