<template>
  <div class="check-box-option">
    <div class="head-option">
      <span>一括更新</span>
    </div>
    <div class="body-head-option">
      <span>一括更新したい項目を選択し、時間を入力してください。</span>
      <div @click="disableStartWork = true">
        <div v-if="disableStartWork" class="body-option">
          <a>出勤時刻</a>
          <input v-model="workStart" placeholder="hh:mm:ss" />
        </div>
        <div v-else class="body-option disable">
          <a>出勤時刻</a>
        </div>
      </div>
      <div @click="disableEndWork = true">
        <div v-if="disableEndWork" class="body-option">
          <a>退勤時刻</a>
          <input v-model="workEnd" placeholder="hh:mm:ss" />
        </div>
        <div v-else class="body-option disable">
          <a>退勤時刻</a>
        </div>
      </div>
      <div @click="disableStartBreak = true">
        <div v-if="disableStartBreak" class="body-option">
          <a>休憩開始</a>
          <input v-model="breakStart" placeholder="hh:mm:ss" />
        </div>
        <div v-else class="body-option disable">
          <a>休憩開始</a>
        </div>
      </div>
      <div @click="disableEndBreak = true">
        <div v-if="disableEndBreak" class="body-option">
          <a>休憩終了</a>
          <input v-model="breakEnd" placeholder="hh:mm:ss" />
        </div>
        <div v-else class="body-option disable">
          <a>休憩終了</a>
        </div>
      </div>
      <div
        v-if="formatTimeError"
        class="text-danger mt-2"
        style="font-size: 11px; line-height: 2.05; margin-left: 37px"
      >
        「hh:mm」型に日付を入力してください
      </div>
      <div
        v-if="error"
        class="text-danger mt-2"
        style="font-size: 11px; line-height: 2.05; margin-left: 37px"
      >
        終了時間は開始時間より後に設定して下さい。
      </div>
      <div class="body-option mt-2">
        <textarea placeholder="コメントを記載してください" v-model="comment"></textarea>
      </div>
      <div
        v-if="emptyCommentError"
        class="text-danger mb-2"
        style="font-size: 11px; line-height: 2.05; margin-left: 37px"
      >
        コメントを入力してください。
      </div>
      <div class="body-option" style="line-height: 2.15">
        <button class="btn-update" @click="updateData">更新</button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";
import Cookies from "js-cookie";

export default {
  data() {
    return {
      disableStartWork: false,
      disableEndWork: false,
      disableStartBreak: false,
      disableEndBreak: false,
      disableStartGoOut: false,
      disableEndGoOut: false,

      workStart: "",
      workEnd: "",
      breakStart: "",
      breakEnd: "",
      goOutStart: "",
      goOutEnd: "",
      comment: null,

      token: Cookies.get("token").split("|"),
      dailyId: [],
      userId: null,
      idStartList: [],
      idEndList: [],
      formatTimeError: false,
      error: false,
      emptyCommentError: false,
    };
  },

  mounted() {
    this.userId = this.$route.query.id;
  },

  computed: {
    ...mapGetters({
      updateItem: "attendance/updateItem",
      allData: "attendance/allData",
    }),
  },

  methods: {
    updateData() {
      this.emptyCommentError = false;
      this.error = false;
      this.formatTimeError = false;
      let attendances = [];
      let breakDetail = [];
      const timeRegex = /^(0?[1-9]|1[0-9]):[0-5][0-9]$/;

      if (!this.isEmpty(this.workStart) || !this.isEmpty(this.workEnd)) {
        let startWorking = new Date("1970-01-01 " + this.workStart);
        let endWorking = new Date("1970-01-01 " + this.workEnd);
        if (!timeRegex.test(this.workStart) || !timeRegex.test(this.workEnd)) {
          return (this.formatTimeError = true);
        } else if (startWorking >= endWorking) {
          return (this.error = true);
        } else if (this.isEmpty(this.comment)) {
          return (this.emptyCommentError = true);
        }
      }

      if (!this.isEmpty(this.breakStart) || !this.isEmpty(this.breakEnd)) {
        var start = new Date("1970-01-01 " + this.breakStart);
        var end = new Date("1970-01-01 " + this.breakEnd);
        if (!timeRegex.test(this.breakStart) || !timeRegex.test(this.breakEnd)) {
          return (this.formatTimeError = true);
        } else if (start >= end) {
          return (this.error = true);
        } else if (this.isEmpty(this.comment)) {
          return (this.emptyCommentError = true);
        }
      } else {
        breakDetail = null;
      }

      if (this.allData) {
        let attend = this.allData.attendances;

        let data = [];
        let subDailyId;
        let dataUpdate;
        this.updateItem.forEach((element) => {
          data.push(attend.filter((x) => x.date === element.fullDate)[0]);
        });

        data.forEach((attendance) => {
          breakDetail = [];
          if (attendance.break_time_detail.length !== 0) {
            var countBreakTime = 0;

            attendance.break_time_detail.forEach((item) => {
              let dataUpdate = {
                break_start: {
                  id: null,
                  started_at: null,
                  is_deleted: null,
                },
                break_end: {
                  id: null,
                  ended_at: null,
                  is_deleted: null,
                },
              };

              dataUpdate.break_start.id = item.break_start.id;
              dataUpdate.break_start.started_at = this.breakStart;
              dataUpdate.break_start.is_deleted = false;
              dataUpdate.break_end.id = item.break_end.id;
              dataUpdate.break_end.ended_at = this.breakEnd;
              dataUpdate.break_end.is_deleted = false;

              countBreakTime += Number((end - start) / 60000);

              breakDetail.push(dataUpdate);
            });

            subDailyId = attendance.daily_attendance_id;
            dataUpdate = {
              date: attendance.date,
              work_schedule_id: attendance.work_schedule_id,
              work_type: attendance.work_type,
              work_started_at: this.workStart,
              work_ended_at: this.workEnd,
              break_time: countBreakTime,
              nap_time: attendance.nap_time,
              wait_time: attendance.wait_time,
              break_time_detail: breakDetail,
              nap_time_detail: null,
              wait_time_detail: null,
              content: this.comment,
            };

            attendances.push(dataUpdate);
            this.dailyId.push(subDailyId);
          } else {
            //in case selected date not have break time before
            breakDetail = [];
            let dataUpdate = {
              break_start: {
                id: null,
                started_at: null,
                is_deleted: null,
              },
              break_end: {
                id: null,
                ended_at: null,
                is_deleted: null,
              },
            };

            dataUpdate.break_start.id = null;
            dataUpdate.break_start.started_at = this.breakStart;
            dataUpdate.break_start.is_deleted = false;
            dataUpdate.break_end.id = null;
            dataUpdate.break_end.ended_at = this.breakEnd;
            dataUpdate.break_end.is_deleted = false;

            breakDetail.push(dataUpdate);

            subDailyId = attendance.daily_attendance_id;
            dataUpdate = {
              date: attendance.date,
              work_schedule_id: attendance.work_schedule_id,
              work_type: attendance.work_type,
              work_started_at: this.workStart,
              work_ended_at: this.workEnd,
              break_time: countBreakTime,
              nap_time: attendance.nap_time,
              wait_time: attendance.wait_time,
              break_time_detail: breakDetail,
              nap_time_detail: null,
              wait_time_detail: null,
              content: this.comment,
            };

            attendances.push(dataUpdate);
            this.dailyId.push(subDailyId);
          }
        });
      }

      let that = this;
      axios.defaults.headers.common = {
        Authorization: "Bearer " + that.token[1],
        "X-Api-Key": that.$store.state.headers["x-api-key"],
      };

      axios
        .put(that.$store.state.serverUrl + `attendance/daily/v1/[${that.dailyId}]`, {
          user_id: that.userId,
          attendances: attendances,
        })
        .then(function (response) {
          if (response.status === 200) {
            attendances.forEach((date) => {
              let newDate = String(date).replace(/-/g, "/");
              that.updateDataBlockChain(newDate);
            });
          }
        })
        .catch(() => {});

      this.$emit("closeTab", attendances);
    },

    // update data BC - SA-0021
    updateDataBlockChain(date) {
      axios.defaults.headers.common = {
        "Content-Type": "application/json",
      };
      const data = this.getDataUpdate();
      let bodyRequest = {
        date: date,
        tenantId: Cookies.get("tenantId"),
        userId: this.userId,
        operationType: "0",
        comment: this.comment,
        latitude: null,
        longitude: null,
        times: [
          {
            time: `[${data.times}]`,
          },
        ],
        statuses: [
          {
            status: `[${data.statuses}]`,
          },
        ],
        targets: [
          {
            target: `[${data.targets}]`,
          },
        ],
        registDateTime: new Date().toLocaleString("ja-JP"),
        registUserId: this.userId,
        version: "0",
      };
      let that = this;
      axios
        .post(this.$store.state.blockChainUrl + "attendances", bodyRequest)
        .then(function (response) {
          that.currentFeatures = response;
        })
        .catch(() => {});
    },

    // format data to BC API update
    getDataUpdate() {
      let data = {
        times: [],
        statuses: [],
        targets: [],
      };
      if (!this.isEmpty(this.workStart)) {
        data.statuses.push("S02");
        data.targets.push("T01");
        data.times.push(this.workStart);
      }
      if (!this.isEmpty(this.workEnd)) {
        data.statuses.push("R01");
        data.targets.push("T02");
        data.times.push(this.workEnd);
      }
      if (!this.isEmpty(this.breakStart)) {
        data.statuses.push("R01");
        data.targets.push("B01");
        data.times.push(this.breakStart);
      }
      if (!this.isEmpty(this.breakEnd)) {
        data.statuses.push("R02");
        data.targets.push("B02");
        data.times.push(this.breakEnd);
      }

      return data;
    },

    isEmpty(value) {
      return value === "" || value === undefined || value === null;
    },
  },
};
</script>
<style scoped>
.check-box-option {
  background: #fffefe;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  position: absolute;
  width: 375px;
  height: 400px;
  padding-right: 16px;
  margin: 50px;
  top: 190px;
}
.head-option {
  margin-left: 13px;
  font-size: 16px;
  line-height: 2.15;
}
.body-head-option {
  margin-left: 18px;
  font-size: 13px;
  line-height: 2.15;
}
.body-option {
  font-size: 14px;
  margin-left: 37px;
  line-height: 2.75;
}
.btn-update {
  border: none;
  background: #126cf3;
  border-radius: 5px;
  float: right;
  width: 93px;
  height: 30px;
  color: #ffffff;
}
textarea {
  width: 300px;
  height: 100%;
  border: 0.5px solid #c4c4c4;
  border-radius: 5px;
  resize: none;
}
input {
  width: 82px;
  height: 25px;
  border: 0.5px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 3px;
  margin-left: 50px;
}
.disable {
  color: #c4c4c4;
}
</style>
